import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ItemContainer = styled.div`
  width: 120px;
  height: 170px;

  cursor: pointer;
`;

const ItemImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 6px;
`;

const ItemContent = styled.div`
  margin-top: 5px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #646464;

  margin-bottom: 5px;
`;
const Price = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: #502ce8;
`;

const RecommendationItem = ({ seq, fileUrls, title, price, isDraged }) => {
  const navigate = useNavigate();

  const priceToString = (price) => {
    if (Number(price) === 0) return "무료";
    return `${Number(price).toLocaleString()}원`;
  };

  const handleClickItem = () => {
    if (isDraged > 0) return;
    navigate(`/model/${seq}`);
  };

  return (
    <ItemContainer onClick={handleClickItem}>
      <ItemImage src={fileUrls && fileUrls[0].url} />
      <ItemContent>
        <Title>{title}</Title>
        <Price>{priceToString(price)}</Price>
      </ItemContent>
    </ItemContainer>
  );
};

export default RecommendationItem;
