import styled from "styled-components";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { requestDeleteModelPost } from "../../axios/model";

const DropdownContainer = styled.div`
  width: 150px;
  position: absolute;
  top: 10px;
  right: 5px;

  background-color: white;
  border-radius: 7px;

  font-size: 15px;
  font-weight: 700;
  color: #4A4A4A;
`;

const DropdownList = styled.ul`
  list-style: none;
  padding-left: 20px;
`;

const DropdownItem = styled.li`
  margin-bottom: 20px;
  cursor: pointer;
`;

const Dropdown = ({ seq, isWriter, showDropdown, setShowDropdown }) => {
  const navigate = useNavigate();
  const ref = useRef();
  
  const handleClickEdit = () => {
    navigate(`/edit/model/${seq}`);
  };

  const handleClickDelete = async () => {
    try {
      await requestDeleteModelPost(seq);
      alert("구인글이 삭제되었습니다.");
      navigate("/");
    }catch(err){
      console.log('delete err : ', err.response);
    }
  }

  const handleClickBackground = (e) => {
    if (showDropdown && !ref.current.contains(e.target)) {
      setShowDropdown(false);
    }
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleClickBackground);
    return () => {
      window.removeEventListener("mousedown", handleClickBackground);
    };
  }, []);

  return (
    <DropdownContainer ref={ref}>
      <DropdownList>
        { isWriter && <DropdownItem onClick={handleClickEdit}>게시글 수정</DropdownItem> }
        { isWriter && <DropdownItem onClick={handleClickDelete}>게시글 삭제</DropdownItem> }
      </DropdownList>
    </DropdownContainer>
  );
}; 

export default Dropdown;
