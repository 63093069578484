import styled from "styled-components";
import BoardTitle from "../board/BoardTitle";
import BoardProfile from "../board/BoardProfile";
import BoardService from "../board/BoardService";
import BoardContent from "../board/BoardContent";

const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -45px;
  
  border-radius: 20px 20px 0px 0px;
  padding: 24px 16px 0 26px;
  background-color: white;
`;

const Board = ({title, nickname, type, applyUrls, location, price, description, applyForm}) => {
  return (
    <BoardContainer>
      <BoardTitle text={title} />
      <BoardProfile nickname={nickname} type={type} applyUrls={applyUrls} />
      <BoardService location={location} price={price} />
      <BoardContent description={description} applyForm={applyForm} />
    </BoardContainer>
  );
};

export default Board;