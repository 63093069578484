import { loginState, userState } from "../../atoms";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { requestKakaoAuth, requestLogin } from "../../axios/login";
import { useRecoilState } from "recoil";

const OAuth2Kakao = () => {
  const navigate = useNavigate();
  const [, setIsLoggedIn] = useRecoilState(loginState);
  const [, setUserData] = useRecoilState(userState);

  const code = new URL(window.location.href).searchParams.get("code");
  console.log(code);

  const login = async (oauthData) => {
    const res = await requestLogin(oauthData);
    console.log(res);
    setIsLoggedIn(true);
    setUserData(res.data);
  };

  const auth = async () => {
    const { data } = await requestKakaoAuth(code);

    if (!data.exist) {
      setUserData(data);
      navigate(`/edit/type`);
      return;
    }

    await login(data);
    navigate("/");
  };

  useEffect(() => {
    auth();
  }, []);

  return <div>로딩 중..</div>;
};

export default OAuth2Kakao;
