import styled from "styled-components";
import Header from "../components/Header";
import EditForm from "../components/editProfilePage/EditForm";
import { useNavigate } from "react-router-dom";
import { XIcon } from "../components/Icon";

const EditProfilePageContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const EditProfilePage = () => {
  const navigate = useNavigate();
  const handleClickExit = () => {
    navigate("/");
  };

  return (
    <EditProfilePageContainer>
      <Header leftChild={<XIcon />} headText="프로필 수정" onClickLeftChild={handleClickExit} />
      <EditForm />
    </EditProfilePageContainer>
  );
};

export default EditProfilePage;