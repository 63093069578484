import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const requestUserInfo = async () => {
  return await axios.get(`${baseUrl}/mopic/user`);
};

export const requestEditProfile = async (inputData) => {
  return await axios.patch(`${baseUrl}/mopic/user/nickname/${inputData}`, { nickname: inputData });
};
