import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const requestKakaoAuth = async (code) => {
  return await axios.get(`${baseUrl}/mopic/oauth2/kakao?code=${code}`);
};

export const requestLogin = async (userData) => {
  return await axios.post(`${baseUrl}/mopic/login`, {
    nickname: userData.nickname,
    provider: userData.provider,
    providerId: userData.providerId,
  });
};

export const requestSignUp = async (userData, types) => {
  return await axios.post(`${baseUrl}/mopic/user`, {
    nickname: userData.nickname,
    provider: userData.provider,
    providerId: userData.providerId,
    types: types,
  });
};
