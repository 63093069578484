import MainHeader from "../components/mainPage/MainHeader";
import MainBanner from "../components/mainPage/MainBanner";
import RecommendationList from "../components/mainPage/recommendation/RecommendationList";
// import StudioList from "../components/mainPage/studio/StudioList";
import ModelWorkList from "../components/mainPage/modelWork/ModelWorkList";

import SideBar from "../components/mainPage/sideBar/SideBar";
import { useState } from "react";
import styled from "styled-components";
import { fetchModelPosts } from "../axios/main";
import { useEffect } from "react";

const MainPageContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

// const EditFormTestBtn = styled.div`
//   width: 30px;
//   height: 30px;

//   border-radius: 50%;
//   background-color: tomato;
//   cursor: pointer;
// `;

const MainPage = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    const fetching = async () => {
      const res = await fetchModelPosts();
      setPostData(res.data);
    };
    fetching();
  }, []);

  const getRecommendationDataList = (data) => {
    return data && [...data].sort(() => Math.random() - 0.5).slice(0, 8);
  };

  const getModelWorkList = (data) => {
    return data && [...data].sort(() => Math.random() - 0.5).slice(0, 3);
  };

  return (
    <MainPageContainer>
      {showSideBar && <SideBar setShowSideBar={setShowSideBar} />}
      <MainHeader setShowSideBar={setShowSideBar} />
      <MainBanner />
      <RecommendationList dataList={getRecommendationDataList(postData)} />
      {/* <StudioList dataList={studioDataList} /> */}
      <ModelWorkList dataList={getModelWorkList(postData)} />
    </MainPageContainer>
  );
};

export default MainPage;
