import styled from "styled-components";
import Dropdown from "./Dropdown";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { loginState } from "../../atoms";
import { LeftArrowIcon, KebabMenuIcon } from "../Icon";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 10;
  
  padding: 35px 26px 0 22px;
  box-sizing: border-box;
  width: 100%;
`; 

const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const DetailHeader = ({ seq, isWriter }) => {
  const navigate = useNavigate();
  const isLoggedIn = useRecoilValue(loginState);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClickLeftIcon = () => {
    navigate("/");
  };

  return (
    <HeaderContainer>
      <IconWrapper onClick={handleClickLeftIcon}>
        <LeftArrowIcon color={"black"} />
      </IconWrapper>
      <IconWrapper onClick={() => setShowDropdown(!showDropdown)}>
        <KebabMenuIcon/>
      </IconWrapper>
      { showDropdown && isWriter && isLoggedIn &&
        <Dropdown seq={seq} isWriter={isWriter} showDropdown={showDropdown} setShowDropdown={setShowDropdown} /> 
      }
    </HeaderContainer>
  );
};

export default DetailHeader;