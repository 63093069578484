import ImageInput from "../modelPostPage/ImageInput";
import TitleInput from "../modelPostPage/titleInput/TitleInput";
import SelectBox from "../modelPostPage/titleInput/SelectBox";
import ContactInput from "../modelPostPage/contentInput/ContactInput";
import ContentInput from "../modelPostPage/contentInput/ContentInput";
import CheckBox from "../CheckBox";
import styled from "styled-components";
import { Button } from "../Button";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { regionList } from "../modelPostPage/dummyData";
import { requestDeleteFile, requestEditModelPost } from "../../axios/model";

const PostFormContainer = styled.form``;

const SelectBoxContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PostForm = ( postData ) => {
  const { id }= useParams();
  const navigate = useNavigate();

  const [imageFileList, setimageFileList] = useState([]);
  const [currentCount, setcurrentCount] = useState(0);
  const [secondRegionList, setSecondRegionList] = useState([]);
  const [deletedFileList, setDeletedFileList] = useState([]);

  const contentText = "올릴 게시글 내용을 작성해주세요.\n(모델에게 금품을 요구할 경우 게시글이 제한될 수 있어요.)";
  const formText = "모델 지원에 필요한 양식이 있다면 적어주세요.";
  
  const form = useForm({
    defaultValues: postData
  });

  const { register, setValue, handleSubmit, watch, reset, formState: { isSubmitting } } = form;

  useEffect(() => {
    if (postData.seq) {
      let defaultValues = {
        firstRegion : postData.location.split(" ")[0],
        secondRegion : postData.location.split(" ")[1],
        kakaotalk: postData.applyUrls[1].url,
        instagram: postData.applyUrls[0].url,
        free: postData.price === 0,
        ...postData
      }
      reset(defaultValues);
      setimageFileList(postData.fileUrls);
      setcurrentCount(postData.fileUrls.length);
    }
  }, [postData]);

  const firstRegion = watch("firstRegion");

  useEffect(() => {
    if (firstRegion) {
      const newSecondRegionList = regionList.find(region => {return region.name === firstRegion}).lst;
      setSecondRegionList(newSecondRegionList);
      setValue("secondRegion", newSecondRegionList[0].name);
    }
  }, [firstRegion]);

  const handleUploadFile = (e) => {
    const files = e.target.files;
    
    for (let i = 0; i < files.length; i++) {
      if (i === 5) break;
      setimageFileList(imageFileList => [...imageFileList, files[i]]);
      setcurrentCount(current => current + 1);
    };
  }

  const handleDeleteFile = (deletedFile) => {
    setcurrentCount(current => current - 1);
    setimageFileList(imageFileList.filter(file => file !== deletedFile));
    setDeletedFileList(deletedFileList => [...deletedFileList, deletedFile]);
  }

  const handleCheckBox = (e) => {
    setValue("price", e.target.checked ? 0 : '');
  }

  const compareFileList = () => {
    const prevFileList = postData.fileUrls;

    if (imageFileList.length !== prevFileList.length) return false;

    for (let file of imageFileList) {
      if (!prevFileList.includes(file)) return false;
    }

    return true;
  }

  const createFormData = (data) => {
    const formData = new FormData();
    const region = `${data.firstRegion} ${data.secondRegion}`;
    const prevFileList = new Set(postData.fileUrls);
    const currFileList = new Set(imageFileList);

    const userData = {
      title: data.title, 
      description: data.description,
      price: data.price,
      form: data.applyForm,
      location: region,
      urlList: [
          {url: data.kakaotalk, type:"KAKAO"},
          {url: data.instagram, type:"INSTAGRAM"}
        ]
      // type: data.type
    };
    
    if (compareFileList()){
      formData.append('imageFileList', null);
    }
    else{
      for (let file of imageFileList) {
      formData.append('imageFileList', file);
      }
    }
    formData.append('board', new Blob([JSON.stringify(userData)], {type: "application/json"}));

    return formData;
  }

  const handleDeleteFileFromDB = async () => {
    const res = true;

    for (let file of deletedFileList){
      if (file.fileName) {
        try {
          await requestDeleteFile(file.fileName);
        } catch(err) {
          console.log('file err : ', err.response);
          res = false;
        }
      }
    }
    return res;
  }

  const onSubmit = async (data) => {
    await new Promise((r) => setTimeout(r, 1000));
    const inputData = createFormData(data);
    
    try{
      await requestEditModelPost(id, inputData);
    } catch(err) {
      console.log('submit err : ', err.response);
      return;
    }

    const res = handleDeleteFileFromDB();
    if (res) {
      alert("구인글이 수정되었습니다.");
      navigate("/");
    }
  };

  const onError = (error) => {
    const errorList = [];
    imageFileList.length || errorList.push("사진");
    error.title && errorList.push("제목");
    error.price && errorList.push("가격");
    error.description && errorList.push("내용");

    const errorMessage = `${errorList.join(', ')}은 필수 입력 항목입니다.`;
    alert(errorMessage);
  };

  return (
    <PostFormContainer onSubmit={handleSubmit(onSubmit, onError)}>
      <ImageInput
        handleDeleteFile={handleDeleteFile}
        handleUploadFile={handleUploadFile}
        currentCount={currentCount}
        imageFileList={imageFileList}
      />
      <TitleInput 
        id="title"
        placeholder="글 제목"
        register={register}
        {...form}
      />
      <TitleInput
        id="region"
        placeholder="서비스 지역"
        disabled={true}
        register={register}
        {...form}>
        <SelectBoxContainer>
          <SelectBox
            id="firstRegion"
            regionData={regionList} 
            register={register}
            {...form}
          />
          <SelectBox
            id="secondRegion"
            regionData={secondRegionList}
            register={register}
            {...form}
          />
        </SelectBoxContainer>
      </TitleInput>
      <TitleInput
        id="price"
        type="number"
        placeholder="₩가격"
        disabled={watch("price") === 0}
        register={register}
        {...form}>
        <CheckBox
          id="free"
          text={"무료"}
          onChange={handleCheckBox}
          register={register}
          {...form}
        />
      </TitleInput>
      <ContentInput
        id="description"
        text="내용"
        placeholder={contentText}
        register={register}
        {...form}
      />
      <ContentInput
        id="applyForm"
        title
        text="지원양식"
        placeholder={formText}
        register={register}
        {...form}
      />
      <ContactInput register={register} {...form} />
      <Button disabled={isSubmitting}>작성완료</Button>
    </PostFormContainer>
  );
};
  
export default PostForm;