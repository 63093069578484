import styled from "styled-components";
import Header from "../components/Header";
import PostForm from "../components/editModelPostPage/PostForm";
import { requestModelPost } from "../axios/model";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { XIcon } from "../components/Icon";

const EditModelPostPageContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const EditModelPostPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    const fetching = async () => {
      const res = await requestModelPost(id);
      setPostData(res.data[0]);
    };
    fetching();
  }, []);

  const handleClickExit = () => {
    navigate("/");
  };

  return (
    <EditModelPostPageContainer>
      <Header leftChild={<XIcon />} headText="구인글 수정" onClickLeftChild={handleClickExit} />
      <PostForm {...postData} />
    </EditModelPostPageContainer>
  );
};

export default EditModelPostPage;