import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetchModelPosts } from "../axios/main";
import Header from "../components/Header";
import { LeftArrowIcon } from "../components/Icon";
import ModelWorkList from "../components/mainPage/modelWork/ModelWorkList";

const ModelAllPageContainer = styled.div`
  padding: 0px 18px;
`;

const ModelAllPage = () => {
  const navigate = useNavigate();
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    const fetching = async () => {
      const res = await fetchModelPosts();
      setPostData(res.data);
    };
    fetching();
  }, []);

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <ModelAllPageContainer>
      <Header
        leftChild={
          <div onClick={handleClickBack}>
            <LeftArrowIcon color="black" width={12} height={20} />
          </div>
        }
        headText={"모델 구해요"}
      />
      <ModelWorkList dataList={postData} removeTitle={true} />
    </ModelAllPageContainer>
  );
};

export default ModelAllPage;
