import ImageInput from "./ImageInput";
import TitleInput from "./titleInput/TitleInput";
import SelectBox from "./titleInput/SelectBox";
import CheckBox from "../CheckBox";
import ContactInput from "./contentInput/ContactInput";
import ContentInput from "./contentInput/ContentInput";
import styled from "styled-components";
import { Button } from "../Button";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { regionList } from "./dummyData";
import { requestPostModel } from "../../axios/model";

const PostFormContainer = styled.form``;

const SelectBoxContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PostForm = () => {
  const navigate = useNavigate();

  const [imageFileList, setimageFileList] = useState([]);
  const [currentCount, setcurrentCount] = useState(0);
  const [secondRegionList, setSecondRegionList] = useState(regionList[0].lst);
  
  const contentText = "올릴 게시글 내용을 작성해주세요.\n(모델에게 금품을 요구할 경우 게시글이 제한될 수 있어요.)";
  const formText = "모델 지원에 필요한 양식이 있다면 적어주세요.";

  const form = useForm({
    defaultValues: {
      fileInput: [],
      title: '',
      price: '',
      free: false,
      firstRegion: '서울특별시',
      secondRegion: '강남구',
      description: '',
      applyForm: '',
      kakaotalk: '',
      instagram: '',
    }
  });

  const { register, setValue, handleSubmit, watch, formState: { isSubmitting } } = form;

  const firstRegion = watch("firstRegion");

  useEffect(() => {
    const newSecondRegionList =
      regionList.find(region => {return region.name === firstRegion}).lst;
    setSecondRegionList(newSecondRegionList);
    setValue("secondRegion", newSecondRegionList[0].name);
  }, [firstRegion]);

  const handleUploadFile = (e) => {
    const files = e.target.files;
    
    for (let i = 0; i < files.length; i++) {
      if (i === 5) break;
      imageFileList.push(files[i]);
      setcurrentCount(current => current + 1);
    };
  }

  const handleDeleteFile = (deletedFile) => {
    setcurrentCount(current => current - 1);
    setimageFileList(imageFileList.filter(file => file !== deletedFile));
  }

  const handleCheckBox = (e) => {
    setValue("price", e.target.checked ? 0 : '');
  }

  const createFormData = (data) => {
    const formData = new FormData();
    const region = `${data.firstRegion} ${data.secondRegion}`;

    const userData = {
      title: data.title, 
      description: data.description,
      price: data.price,
      form: data.applyForm,
      location: region,
      urlList: [
          {url: data.kakaotalk, type:"KAKAO"},
          {url: data.instagram, type:"INSTAGRAM"}
        ]
      // type: data.type
    };

    for (let file of imageFileList) {
      formData.append('imageFileList', file);
    }
    formData.append('board', new Blob([JSON.stringify(userData)], {type: "application/json"}));
    return formData;
  }

  const onSubmit = async (data) => {
    await new Promise((r) => setTimeout(r, 1000));
    const inputData = createFormData(data);

    try {
      await requestPostModel(inputData);
      alert("구인글이 등록되었습니다.");
      navigate("/");
    } catch(err) {
      console.log('Register err : ', err.response);
    }
  }

  const onError = (error) => {
    const errorList = [];
    imageFileList.length || errorList.push("사진");
    error.title && errorList.push("제목");
    error.price && errorList.push("가격");
    error.description && errorList.push("내용");

    const errorMessage = `${errorList.join(', ')}은 필수 입력 항목입니다.`;
    alert(errorMessage);
  };

  return (
    <PostFormContainer onSubmit={handleSubmit(onSubmit, onError)}>
      <ImageInput
        handleDeleteFile={handleDeleteFile}
        handleUploadFile={handleUploadFile}
        currentCount={currentCount}
        imageFileList={imageFileList}
      />
      <TitleInput 
        id="title"
        placeholder="글 제목"
        register={register}
        {...form}
      />
      <TitleInput
        id="region"
        placeholder="서비스 지역"
        disabled={true}
        register={register}
        {...form}>
        <SelectBoxContainer>
          <SelectBox
            id="firstRegion"
            regionData={regionList} 
            register={register}
            {...form}
          />
          <SelectBox
            id="secondRegion"
            regionData={secondRegionList} 
            register={register}
            {...form}
          />
        </SelectBoxContainer>
      </TitleInput>
      <TitleInput
        id="price"
        type="number"
        placeholder="₩가격"
        disabled={watch("price") === 0}
        register={register}
        {...form}>
        <CheckBox
          id="free"
          text={"무료"}
          onChange={handleCheckBox}
          register={register}
          {...form}
        />
      </TitleInput>
      <ContentInput
        id="description"
        text="내용"
        placeholder={contentText}
        register={register}
        {...form}
      />
      <ContentInput
        id="applyForm"
        title
        text="지원양식"
        placeholder={formText}
        register={register}
        {...form}
      />
      <ContactInput register={register} {...form} />
      <Button disabled={isSubmitting}>작성완료</Button>
    </PostFormContainer>
  );
};
  
export default PostForm;