import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { loginState, userState } from "../atoms";
import { Button } from "../components/Button";
import Header from "../components/Header";
import { LeftArrowIcon } from "../components/Icon";
import TypeList from "../components/userTypePage/TypeList";
import { useRecoilState } from "recoil";
import { requestLogin, requestSignUp } from "../axios/login";

const UserTypePageContainer = styled.div`
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 160px;
  margin-bottom: 150px;
  padding-left: 18px;
  padding-right: 18px;

  gap: 65px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
`;

const SubText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #a6a6a6;
`;

const UserTypePage = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState([]);
  const [, setIsLoggedIn] = useRecoilState(loginState);
  const [userData, setUserData] = useRecoilState(userState);

  const handleClickBack = () => {
    navigate("/login");
  };

  const handleClickStart = async () => {
    const res = await requestSignUp(userData, selectedType);
    console.log(res);
    await requestLogin(userData);

    setIsLoggedIn(true);
    setUserData({ ...userData, types: selectedType });

    navigate("/");
  };

  return (
    <UserTypePageContainer>
      <Header
        leftChild={
          <div onClick={handleClickBack}>
            <LeftArrowIcon color="black" width={12} height={20} />
          </div>
        }
      />
      <ContentContainer>
        <TextContainer>
          <Title>
            어떤 유형의 <br /> 작업자이신가요?
          </Title>
          <SubText>최대 3개 선택 가능</SubText>
        </TextContainer>
        <TypeList selectedType={selectedType} setSelectedType={setSelectedType} />
      </ContentContainer>
      <Button onClick={handleClickStart} disabled={selectedType.length === 0}>
        시작하기
      </Button>
    </UserTypePageContainer>
  );
};

export default UserTypePage;
