import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import OAuth2Kakao from "./pages/auth/OAuth2Kakao";
import EditProfilePage from "./pages/EditProfilePage";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import ModelDetailPage from "./pages/ModelDetailPage";
import UserTypePage from "./pages/UserTypePage";
import ModelPostPage from "./pages/ModelPostPage";
import ModelAllPage from "./pages/ModelAllPage";
import EditModelPostPage from "./pages/EditModelPostPage";

const AppContainer = styled.div`
  min-height: 100vh;
  box-sizing: border-box;
  background-color: white;

  @media (min-width: 440px) {
    width: 438px;
  }
  @media (max-width: 440px) {
    width: 100vw;
  }
`;

function App() {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="model/:id" element={<ModelDetailPage />} />
          <Route path="models" element={<ModelAllPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="edit/type" element={<UserTypePage />} />
          <Route path="edit/profile" element={<EditProfilePage />} />
          <Route path="mopic/oauth2/kakao" element={<OAuth2Kakao />} />
          <Route path="post/model" element={<ModelPostPage />} />
          <Route path="edit/model/:id" element={<EditModelPostPage />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;
