import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const requestPostModel = async (inputData) => {
  // 게시글 등록
  return await axios.post(`${baseUrl}/mopic/model`, inputData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const requestModelPost = async (seq) => {
  return await axios.get(`${baseUrl}/mopic/model/?seq=${seq}`);
};

export const requestDeleteModelPost = async (seq) => {
  return await axios.delete(`${baseUrl}/mopic/model/${seq}`);
};

export const requestDeleteFile = async (fileName) => {
  return await axios.delete(`${baseUrl}/mopic/file/${fileName}`);
};

export const requestEditModelPost = async (seq, inputData) => {
  return await axios.patch(`${baseUrl}/mopic/model/${seq}`, inputData);
};
