import DetailHeader from "../components/detailPage/DetailHeader";
import DetailImages from "../components/detailPage/DetailImages";
import Board from "../components/detailPage/board/Board";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { requestModelPost } from "../axios/model";

const ModelDetailPageContainer = styled.div`
  position: relative;
`;

const ModelDetailPage = () => {
  const { id }= useParams();
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    const fetching = async () => {
      const res = await requestModelPost(id);
      setPostData(res.data[0]);
    };
    fetching();
  }, []);
  
  return (
    <ModelDetailPageContainer>
      <DetailHeader seq={id} isWriter={postData.boolWriter} />
      <DetailImages images={postData.fileUrls} />
      <Board {...postData} />
    </ModelDetailPageContainer>
  );
};

export default ModelDetailPage;